import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import styles from './LogIn.module.css';
import ErrorAlert from '../../components/ErrorAlert';
import { RingLoader } from 'react-spinners';
import SignupScreenWrapper from '../../components/SignupScreenWrapper/SignupScreenWrapper';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomButton from '../../components/CustomButton/Custombutton';
import { ApiContext } from '../../context/ApiContext';

const LogIn = () => {
	const { loginUser, getCurrentAuthenticatedUser } = useContext(AmplifyAuthContext);
	const { getUserByCogId } = useContext(ApiContext);

	const navigate = useNavigate();

	const [loading, setLoading] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const signUpText = `Don't have an account? Sign up now.`;

	const handleShowError = message => {
		setErrorMessage(message);
		setErrorOpen(true);
	};

	const handleCloseError = () => {
		setErrorOpen(false);
	};

	const handleSignIn = async event => {
		event.preventDefault();
		const { email, password } = event.target.elements;

		try {
			setLoading(true);
			const { success, errorMsg, response } = await loginUser(email.value, password.value);

			if (!success) {
				handleShowError(errorMsg);
			} else {
				await getCurrentAuthenticatedUser();
				await getUserByCogId(response?.username);
				navigate('/', { replace: true });
			}
		} catch (error) {
			handleShowError('An error occurred');
		} finally {
			setLoading(false);
		}
	};

	return (
		<SignupScreenWrapper>
			{loading && (
				<div className={styles.loaderOverlay}>
					<RingLoader color="#ffffff" size={60} />
				</div>
			)}
			<form onSubmit={handleSignIn}>
				<h2>Log In</h2>

				<CustomInput
					placeholder="john@gmail.com"
					inputTitle="Email"
					name="email"
					type={'text'}
					className={styles.formItems}
					required
				/>

				<CustomInput
					placeholder="********"
					inputTitle="Password"
					name="password"
					type={'password'}
					className={styles.formItems}
					required
					showVisibilityIcon={true}
				/>

				<div className={styles.createAccountLink}>
					<Link to="/signup">{signUpText}</Link>
				</div>

				<CustomButton title="Submit" type={'submit'} style={{ width: '100%' }} />
				<ErrorAlert open={errorOpen} message={errorMessage} onClose={handleCloseError} />
			</form>
		</SignupScreenWrapper>
	);
};

export default LogIn;
