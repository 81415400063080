import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import styles from './OtpScreen.module.css';
import ErrorAlert from '../../components/ErrorAlert';
import { RingLoader } from 'react-spinners';
import { UserSessionContext } from '../../context/UserSessionContext';
import { ApiContext } from '../../context/ApiContext';
import SignupScreenWrapper from '../../components/SignupScreenWrapper/SignupScreenWrapper';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomButton from '../../components/CustomButton/Custombutton';
import { useSnackbar } from 'notistack';

export default function OtpScreen() {
	const { getCurrentAuthenticatedUser } = useContext(AmplifyAuthContext);
	const { cognitoUser } = useContext(UserSessionContext);
	const { createUserWithCognitoId } = useContext(ApiContext);

	const navigate = useNavigate();
	const location = useLocation();
	const { email, otpDeliveryDestination } = location.state;
	const [loading, setLoading] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [errorDetails, setErrorDetails] = useState({
		type: 'error',
		message: '',
		title: 'Error'
	});
	const { enqueueSnackbar } = useSnackbar();

	const setError = (type, title, message) => {
		setErrorDetails({
			type,
			title,
			message
		});
		setErrorOpen(true);
	};

	const handleCloseError = () => {
		setErrorOpen(false);
	};

	const handleConfirm = async event => {
		event.preventDefault();

		const { otp } = event.target.elements;

		try {
			setLoading(true);
			await Auth.confirmSignUp(email, otp.value);

			const { success, errorMsg } = await createUserWithCognitoId(cognitoUser);

			await getCurrentAuthenticatedUser();

			if (success) {
				enqueueSnackbar(`User created successfully`, {
					variant: 'success'
				});
				return navigate('/login', { replace: true });
			}
			setError('error', 'Error', errorMsg);
		} catch (error) {
			setError('error', 'Error', 'OTP verification failed. Please verify the OTP and try again.');
		} finally {
			setLoading(false);
		}
	};

	return (
		<SignupScreenWrapper>
			{loading && (
				<div className={styles.loaderOverlay}>
					<RingLoader color="#ffffff" size={60} />
				</div>
			)}
			<div>
				<h2>Verification</h2>

				<h5 style={{ marginBottom: 20 }}>Code sent to {otpDeliveryDestination}</h5>

				<form onSubmit={handleConfirm}>
					<CustomInput inputTitle="OTP" name="otp" type="text" className={styles.formItems} required />

					<div className={styles.paddingDiv}></div>
					<CustomButton title="Confirm" style={{ width: '100%' }} />
				</form>
			</div>
			<ErrorAlert
				title={errorDetails.title}
				severity={errorDetails.type}
				open={errorOpen}
				message={errorDetails.message}
				onClose={() => handleCloseError(false)}
			/>
		</SignupScreenWrapper>
	);
}
