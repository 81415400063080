import { v4 as uuidv4 } from 'uuid';
import { styled } from '@mui/material/styles';

export const drawerWidth = 330; // in px

export const DrawerHeader = styled('div')(({ theme, open }) => ({
	display: 'flex',
	alignItems: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: 'flex-end'
}));

export const initialChat = [
	{
		id: uuidv4(),
		sender: 'GPT',
		message: `Welcome to chat with Database! Feel free to ask any queries or request information related to the all listed database. I'm here to assist you!`
	}
];

export const DBList = [
	{
		id: 1,
		name: 'DataBase-1',
		chat: [
			{
				response: 'text',
				userQuestion: 'Question'
			}
		]
	},
	{
		id: 2,
		name: 'DataBase-2',
		chat: [
			{
				response: 'text',
				userQuestion: 'Question'
			}
		]
	},
	{
		id: 3,
		name: 'DataBase-3',
		chat: [
			{
				response: 'text',
				userQuestion: 'Question'
			}
		]
	},
	{
		id: 4,
		name: 'DataBase-4',
		chat: [
			{
				response: 'text',
				userQuestion: 'Question'
			}
		]
	},
	{
		id: 5,
		name: 'DataBase-5',
		chat: [
			{
				response: 'text',
				userQuestion: 'Question'
			}
		]
	}
];

export const notificationTypes = {
	success: 'success',
	error: 'error',
	info: 'info',
	warning: 'warning'
};

export const navListoptions = ['Add / View user', 'View Database', 'Delete Database'];

export const navListConstants = {
	addUser: 'Add / View user',
	viewDatabase: 'View Database',
	deleteDatabase: 'Delete Database'
};
