import React from 'react';
import styles from './SignupScreenWrapper.module.css';
import logoImage from '../../asset/images/magure_icon.webp';
import { Fade } from '@mui/material';

export default function SignupScreenWrapper({ children }) {
	return (
		<div className={styles.container}>
			<Fade in={true} mountOnEnter unmountOnExit>
				<div className={styles.content}>
					<div className={styles.header}>
						<img src={logoImage} alt="Logo" className={styles.logo} />
						<p className={styles.text}>Chat with DB!</p>
					</div>
					{children}
				</div>
			</Fade>
		</div>
	);
}
