import { Amplify } from 'aws-amplify';
import AmplifyAuthContextProvider, { AmplifyAuthContext } from './context/AmplifyAuthContext';
import { AwsConfigAuth } from './config/auth';
import { SnackbarProvider } from 'notistack';
import ErrorFallback from './components/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import Routes from './Routes';
import UserSessionContextProvider from './context/UserSessionContext';
import { useContext, useEffect } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ApiContextProvider from './context/ApiContext';
import { colors } from './constant/colors';

Amplify.configure(AwsConfigAuth);

const theme = createTheme({
	palette: {
		type: 'dark',
		primary: {
			main: '#3f51b5'
		},
		secondary: {
			main: '#f50057'
		},
		typography: {
			fontFamily: 'Plus-Jakarta-Sans',
			fontWeightLight: 300,
			fontWeightRegular: 400,
			fontWeightMedium: 500,
			fontWeightBold: 700
		}
	}
});

const snackProperties = {
	autoHideDuration: 1600,
	TransitionProps: { direction: 'down' },
	anchorOrigin: { horizontal: 'center', vertical: 'top' },
	style: { color: colors.white, background: colors.black }
};

function AppWrap() {
	return (
		<ErrorBoundary FallbackComponent={ErrorFallback}>
			<ThemeProvider theme={theme}>
				<SnackbarProvider {...snackProperties}>
					<UserSessionContextProvider>
						<ApiContextProvider>
							<AmplifyAuthContextProvider>
								<App />
							</AmplifyAuthContextProvider>
						</ApiContextProvider>
					</UserSessionContextProvider>
				</SnackbarProvider>
			</ThemeProvider>
		</ErrorBoundary>
	);
}

function App() {
	const { getCurrentAuthenticatedUser } = useContext(AmplifyAuthContext);

	useEffect(() => {
		(async () => {
			await getCurrentAuthenticatedUser();
		})();
	}, [getCurrentAuthenticatedUser]);

	return <Routes />;
}

export default AppWrap;
