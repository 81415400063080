import React, { useRef } from 'react';
import CloseIcon from './icons/CloseIcon';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';

const BaseModal = ({
	open,
	onClose,
	title,
	fontWeight = '400',
	children,
	maxWidth = 'md',
	isPositionTop = false,
	styles,
	closeOnBackgroundClick = true
}) => {
	const modalRef = useRef(null);
	const sx = {
		'& .MuiDialog-container': {
			alignItems: 'flex-start'
		}
	};

	const handleEntering = () => {
		if (modalRef.current != null) {
			modalRef.current.focus();
		}
	};

	return (
		<Dialog
			ref={modalRef}
			open={open}
			onClose={closeOnBackgroundClick ? onClose : undefined}
			fullWidth
			maxWidth={maxWidth}
			style={styles}
			sx={isPositionTop ? sx : {}}
			TransitionProps={{ onEntering: handleEntering }}
		>
			<DialogTitle textAlign="center" fontWeight={fontWeight}>
				{title}
				<CloseIcon
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8
					}}
				/>
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
		</Dialog>
	);
};

export default BaseModal;
