import React from 'react';
import Alert from '@mui/material/Alert';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

const ErrorAlert = ({ open, message, onClose, severity = 'error', title = 'Error' }) => (
	// severity: success, error, info, warning
	<Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
		<DialogTitle>{title}</DialogTitle>
		<DialogContent>
			<Alert severity={severity}>{message}</Alert>
		</DialogContent>
		<DialogActions style={{ paddingLeft: 0 }}>
			<Button onClick={onClose}>Close</Button>
		</DialogActions>
	</Dialog>
);

export default ErrorAlert;
