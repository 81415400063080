import { createContext, useState } from 'react';

export const UserSessionContext = createContext();

const UserSessionContextProvider = ({ children }) => {
	const [allDatabases, setAllDatabases] = useState([]);
	const [showLoader, setShowLoader] = useState(true);
	const [initialLoading, setInitialLoading] = useState(true);
	const [authenticatedUser, setAuthenticatedUser] = useState(null);
	const [cognitoUser, setCognitoUser] = useState(null);
	const [selectedDB, setSelectedDB] = useState(null);
	const [selectedChat, setSelectedChat] = useState(null);
	const [currentUser, setCurrentUser] = useState(null);
	const [databaseUsers, setDatabaseUsers] = useState([]);

	return (
		<UserSessionContext.Provider
			value={{
				allDatabases,
				setAllDatabases,
				currentUser,
				setCurrentUser,
				showLoader,
				setShowLoader,
				authenticatedUser,
				setAuthenticatedUser,
				cognitoUser,
				setCognitoUser,
				selectedDB,
				setSelectedDB,
				selectedChat,
				setSelectedChat,
				databaseUsers,
				setDatabaseUsers,
				initialLoading,
				setInitialLoading
			}}
		>
			{children}
		</UserSessionContext.Provider>
	);
};

export default UserSessionContextProvider;
