import React from 'react';
import styles from './NavHeader.module.scss';
import { DrawerHeader } from '../../../constant/Constant';
import { Box, CardMedia } from '@mui/material';
import logo from '../../../asset/images/magure_icon.webp';

export default function NavHeader({ open, handleDrawerToggle }) {
	return (
		<DrawerHeader open={open} className={styles.container}>
			<Box sx={{ display: 'flex', borderRadius: '50%', alignItems: 'center' }}>
				<CardMedia
					component="img"
					src={logo}
					alt="logo"
					style={{
						cursor: 'pointer',
						width: '30px',
						height: '30px'
					}}
				/>
				<Box className={styles.title}>Chat with Database</Box>
			</Box>
		</DrawerHeader>
	);
}
