import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes as ApplicationRoutes, Route, Navigate } from 'react-router-dom';

import SignUp from './screens/SignUp/SignUp';
import LogIn from './screens/LogIn/LogIn';
import Home from './screens/Home/Home';
import OtpScreen from './screens/OtpScreen/OtpScreen';
import PageNotFound from './screens/PageNotFound';
import { UserSessionContext } from './context/UserSessionContext';

function Routes() {
	const { authenticatedUser } = useContext(UserSessionContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(false);
	}, [authenticatedUser]);

	if (loading) {
		return null;
	}

	return (
		<Router>
			<ApplicationRoutes>
				<Route path="/" element={authenticatedUser ? <Home /> : <Navigate to="/login" />} />
				<Route path="/login" element={authenticatedUser ? <Navigate to="/" /> : <LogIn />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/otp" element={<OtpScreen />} />
				<Route path="*" element={<PageNotFound />} />
			</ApplicationRoutes>
		</Router>
	);
}

export default Routes;
