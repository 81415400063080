import { Box, InputBase, Stack, Typography, IconButton, Tooltip, CircularProgress, Fade, Button } from '@mui/material';

import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import ReplayIcon from '@mui/icons-material/Replay';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuidv4 } from 'uuid';
import { initialChat, notificationTypes } from '../constant/Constant';
import { colors } from '../constant/colors';
import { UserSessionContext } from '../context/UserSessionContext';
import ConfirmationAlert from './ConfirmationAlert';
import { ApiContext } from '../context/ApiContext';
import ErrorAlert from './ErrorAlert';
import { isAdmin } from '../utils/utils';
import DatabaseDiagramModal from './DatabaseDiagramModal';

const ChatSection = ({ setSnackbarDetails, setIsSnackbarVisible }) => {
	const [messageText, setMessageText] = useState('');
	const { currentUser, selectedDB, setSelectedChat, setSelectedDB } = useContext(UserSessionContext);
	const { deleteDatabseById, deleteChatHistory, getChatMessagesById, postQuery } = useContext(ApiContext);
	const [messages, setMessages] = useState([]);
	const chatContainerRef = useRef(null);
	const inputRef = useRef(null);
	const [isProcessingQuery, setIsProcessingQuery] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showDbDiagramModal, setShowDbDiagramModal] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [modalDetails, setModalDetails] = useState({
		type: 'error',
		message: '',
		title: 'Error'
	});
	const deleteDocumentChatText = (
		<>
			Are you sure you want to clear all the chat history of <b>{selectedDB?.name}</b>?
		</>
	);
	const deleteDocumentText = (
		<>
			Are you sure you want to delete <b>{selectedDB?.name}</b> database?
		</>
	);

	const setModal = (type, title, message) => {
		setModalDetails({
			type,
			title,
			message
		});
		setErrorOpen(true);
	};

	const setSnackbar = (variant, title) => {
		setSnackbarDetails({
			variant,
			title
		});
		setIsSnackbarVisible(true);
	};

	const getAllChatData = useCallback(
		async (userId, DBId) => {
			const { success, response } = await getChatMessagesById(userId, DBId);
			if (success) {
				if (response.length > 0) {
					setSelectedChat(response);
					setMessages(response);
				} else {
					setMessages(initialChat);
				}
			}
		},
		[getChatMessagesById, setSelectedChat]
	);

	useEffect(() => {
		if (currentUser?.user_id && selectedDB?.database_id) {
			getAllChatData(currentUser?.user_id, selectedDB.database_id);
		} else {
			setMessages(initialChat);
		}
	}, [currentUser?.user_id, getAllChatData, selectedDB]);

	const [confirmationValues, SetconfirmationValues] = useState({
		label: '',
		message: '',
		isCancelVisible: false,
		type: null
	});
	const modalType = {
		deleteDB: 'DeleteDB',
		deleteChat: 'DeleteChat'
	};

	useEffect(() => {
		scrollChatContainerToBottom();
	}, [messages]);

	const scrollChatContainerToBottom = () => {
		if (chatContainerRef.current) {
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
		}
	};

	const sendMessage = async event => {
		if (selectedDB === null) {
			return setShowAlert(true);
		}
		setIsProcessingQuery(true);
		const lastAddedMessage = {
			id: uuidv4(),
			sender: 'You',
			message: messageText
		};

		setMessages(prev => [...prev, lastAddedMessage]);
		setMessageText('');

		const payload = {
			question: messageText,
			database_id: selectedDB.database_id,
			user_id: currentUser.user_id
		};

		const { response } = await postQuery(payload);
		setMessages(prev => [
			...prev,
			{
				id: uuidv4(),
				sender: 'GPT',
				message: response || 'Something went wrong, please try again later.'
			}
		]);
		inputRef.current.focus();
		// if (!success && errorMsg) {
		// 	setModal('error', 'Error', errorMsg);
		// }
		setIsProcessingQuery(false);
	};

	const handleDeleteChat = async (currentUser, selectedDB) => {
		await deleteChatHistory(currentUser?.user_id, selectedDB.database_id);
		getAllChatData(currentUser?.user_id, selectedDB.database_id);
	};

	const handleDeleteDB = async DB => {
		setShowConfirmation(false);
		const { success, errorMsg } = await deleteDatabseById(DB, currentUser.user_id);
		if (success) {
			setSnackbar(notificationTypes.success, 'Database deleted successfully');
			return setSelectedDB(null);
		}
		return setModal(notificationTypes.error, 'Error', errorMsg);
	};

	const handleIconClick = (label, message, type, onConfirm, onCancel) => {
		SetconfirmationValues({ label: label, message: message, isCancelVisible: true, type: type });
		setShowConfirmation(true);
	};

	const handleConfirmation = () => {
		setShowConfirmation(false);
		switch (confirmationValues.type) {
			case modalType.deleteDB:
				handleDeleteDB(selectedDB);
				break;
			case modalType.deleteChat:
				handleDeleteChat(currentUser, selectedDB);
				break;
			default:
				break;
		}
	};

	return (
		<Fade in={!!selectedDB?.database_id}>
			<Box sx={styles.container}>
				<Box sx={styles.chatHeader}>
					<Box>{selectedDB?.name && <Typography sx={styles.headerTitle}>{selectedDB?.name}</Typography>}</Box>
					<Box sx={{ minWidth: 100, display: 'flex', justifyContent: 'flex-end' }}>
						<Button variant="outlined" onClick={() => setShowDbDiagramModal(true)} style={styles.schemaButtonStyle}>
							<Typography style={{ fontSize: '13px' }}>View Schema</Typography>
						</Button>
						{isAdmin(currentUser, selectedDB) && (
							<Tooltip title="Delete Database" variant="soft">
								<span>
									<IconButton
										onClick={() => handleIconClick('Delete Database', deleteDocumentText, modalType.deleteDB)}
										disabled={!selectedDB}
									>
										<DeleteIcon />
									</IconButton>
								</span>
							</Tooltip>
						)}
						<Tooltip title="Delete Chat" variant="soft">
							<span>
								<IconButton
									onClick={() => handleIconClick('Delete Chat', deleteDocumentChatText, modalType.deleteChat)}
									disabled={!selectedDB}
								>
									<ReplayIcon />
								</IconButton>
							</span>
						</Tooltip>
					</Box>
				</Box>
				<Box sx={styles.chatConatiner} ref={chatContainerRef}>
					{messages.map((chat, index) => {
						const isUserMessage = chat.sender === 'You';
						return (
							<Stack
								key={index}
								direction="row"
								sx={[styles.eachChatContainer, { flexDirection: isUserMessage ? 'row-reverse' : '' }]}
							>
								<Fade in={true} style={{ transitionDelay: !isProcessingQuery ? `${index * 20}ms` : '0ms' }}>
									<Typography
										sx={{
											fontSize: '0.9rem',
											wordWrap: 'break-word',
											overflowWrap: 'break-word',
											hyphens: 'auto',
											maxWidth: '80%',
											whiteSpace: 'pre-wrap',
											backgroundColor: !isUserMessage ? '#ffffff' : '#D1D1D1',
											borderRadius: !isUserMessage ? '0px 1.5rem 1.5rem 1.5rem' : '1.5rem 0 1.5rem 1.5rem',
											boxShadow: '2px 5px 25px 1px #0000000D',
											padding: '1rem'
										}}
										color={colors.chatText}
									>
										{chat.message && typeof chat.message === 'string' ? chat.message.trim() : ''}
									</Typography>
								</Fade>

								{isProcessingQuery && index === messages.length - 1 && (
									<CircularProgress sx={{ color: colors.black, marginRight: '10px', alignSelf: 'center' }} size={24} />
								)}
							</Stack>
						);
					})}
				</Box>

				<Box sx={styles.inputContainer}>
					<InputBase
						placeholder={isProcessingQuery ? 'Processing...' : 'Ask a question...'}
						value={messageText}
						autoFocus
						onChange={e => setMessageText(e.target.value)}
						sx={[styles.inputBox]}
						inputRef={inputRef}
						onKeyDown={event => {
							if (event.key === 'Enter' && messageText.length) {
								sendMessage();
							}
						}}
						inputProps={{
							style: {
								color: colors.black,
								fontSize: '0.9rem'
							},
							disabled: isProcessingQuery
						}}
					/>
					<IconButton onClick={sendMessage} disabled={!messageText.length} style={styles.sendButton}>
						{isProcessingQuery ? (
							<CircularProgress sx={{ color: colors.white }} size={24} />
						) : (
							<SendIcon
								sx={{
									color: colors.white
								}}
							/>
						)}
					</IconButton>
				</Box>

				<DatabaseDiagramModal
					open={showDbDiagramModal}
					onClose={() => setShowDbDiagramModal(false)}
					selectedListitem={selectedDB}
				/>
				<ConfirmationAlert
					open={showAlert}
					btnText="Ok"
					isCancelVisible={false}
					label={'Select Database'}
					message="Please select any Database to start the chat"
					onConfirm={() => setShowAlert(false)}
				/>
				<ConfirmationAlert
					open={showConfirmation}
					isCancelVisible={confirmationValues.isCancelVisible}
					label={confirmationValues.label}
					message={confirmationValues.message}
					onConfirm={() => handleConfirmation()}
					onCancel={() => setShowConfirmation(false)}
				/>
				<ErrorAlert
					title={modalDetails.title}
					severity={modalDetails.type}
					open={errorOpen}
					message={modalDetails.message}
					onClose={() => setErrorOpen(false)}
				/>
			</Box>
		</Fade>
	);
};

const styles = {
	container: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		backgroundColor: '#F4F4F4',
		position: 'relative'
	},
	chatConatiner: {
		height: '92%',
		overflow: 'auto',
		'& > *+*': {
			/* hide scrollbar for IE, Edge and Firefox */
			msOverflowStyle: 'none'
		},
		'::-webkit-scrollbar': {
			/* hide scrollbar for chrome, safari and opera */
			display: 'none'
		},
		paddingTop: 0,
		padding: '3em 1em .5em 1em'
	},
	chatHeader: {
		alignItems: 'center',
		backgroundColor: colors.grayLight,
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0.16em 1em',
		position: 'absolute',
		right: 0,
		left: 0,
		top: 0,
		zIndex: 2,
		boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
		minHeight: '45px'
	},
	eachChatContainer: { marginY: '1rem' },
	userIndicator: {
		width: 50,
		height: 50,
		backgroundColor: colors.grayLight,
		borderRadius: 2,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	inputContainer: {
		flex: 1,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		flexDirection: 'row',
		padding: '0 1em',
		position: 'absolute',
		bottom: '1em',
		right: 0,
		left: 0
	},
	inputBox: {
		backgroundColor: colors.primary,
		border: 0.5,
		borderColor: colors.borderColor,
		width: '100%',
		height: '45px',
		borderRadius: 3,
		padding: '1rem'
	},
	schemaButtonStyle: {
		width: 125,
		height: 30,
		alignSelf: 'center',
		borderColor: 'black',
		color: 'black',
		borderRadius: 20,
		borderWidth: 1,
		textTransform: 'none'
	},
	sendButton: {
		backgroundColor: colors.black,
		borderRadius: '50%',
		marginLeft: '.8rem'
	},
	headerLeftSection: {
		display: 'flex'
	},
	headerTitle: { fontSize: 16, fontWeight: 700, textAlign: 'center' }
};

export default ChatSection;
