import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from '../context/ApiContext';
import { UserSessionContext } from '../context/UserSessionContext';
import { isAdmin } from '../utils/utils';
import BaseModal from './BaseModal';
import SearchUser from './searchUser';

const AddDBUserModal = ({ open, onClose, onSubmit, selectedListitem }) => {
	const { currentUser, databaseUsers, setDatabaseUsers, selectedDB, showLoader } = useContext(UserSessionContext);
	const { getUsersByDatabaseId } = useContext(ApiContext);

	useEffect(() => {
		setDatabaseUsers([]);
		const fetchData = async () => {
			const DBId = selectedListitem?.database_id;
			if (open && DBId) {
				await getUsersByDatabaseId(DBId);
			}
		};

		fetchData();
	}, [getUsersByDatabaseId, open, selectedListitem, setDatabaseUsers]);

	const handleSubmit = selectedUser => {
		const encodedEmail = encodeURIComponent(selectedUser.user_email_id);
		onSubmit({ userEmail: encodedEmail });
	};

	return (
		<BaseModal
			title={isAdmin(currentUser, selectedDB) ? 'Add User' : ''}
			fontWeight="700"
			open={open}
			onClose={onClose}
			maxWidth="sm"
			isPositionTop={true}
			closeOnBackgroundClick={false}
		>
			{isAdmin(currentUser, selectedListitem) && <SearchUser onOptionSelect={handleSubmit} />}
			<>
				<Typography sx={{ fontSize: 20, fontWeight: 700, textAlign: 'center' }}>Users</Typography>
				<TableContainer component={Paper} sx={{ maxHeight: 240 }}>
					<Table sx={{ flex: 1 }} size="small" stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell align="left">S.No</TableCell>
								<TableCell align="left">Email</TableCell>
								<TableCell>Name</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{showLoader ? (
								<TableRow>
									<TableCell colSpan={3}>Loading...</TableCell>
								</TableRow>
							) : (
								databaseUsers.map((row, i) => (
									<TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
										<TableCell align="left" component="th" scope="row">
											{i + 1}
										</TableCell>
										<TableCell align="left" component="th" scope="row">
											{row.user_email_id}
										</TableCell>
										<TableCell>{row.name}</TableCell>
									</TableRow>
								))
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		</BaseModal>
	);
};

export default AddDBUserModal;
