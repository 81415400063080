import { useSnackbar } from 'notistack';
import { useEffect } from 'react';

const CustomSnackbar = ({ variant = 'default', title, isVisible }) => {
	const { enqueueSnackbar } = useSnackbar();
	// variant can be: success, error, info, warning

	useEffect(() => {
		if (isVisible) {
			enqueueSnackbar(title, {
				variant: variant
			});
		}
	}, [enqueueSnackbar, variant, title, isVisible]);
};

export default CustomSnackbar;
