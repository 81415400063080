import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ApiContext } from '../context/ApiContext';
import { Box } from '@mui/material';
import CustomButton from './CustomButton/Custombutton';
import { colors } from '../constant/colors';

const SearchUser = ({ onOptionSelect }) => {
	const { searchUserByEmail } = useContext(ApiContext);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [value, setValue] = useState(null);
	const [inputValue, setInputValue] = useState('');
	const [options, setOptions] = useState([]);
	const [fetchedUsers, setFetchedUsers] = useState([]);
	const [selectedFromOptions, setSelectedFromOptions] = useState(false);
	const [helperText, setHelperText] = useState('');
	const [noOptionsText, setNoOptionsText] = useState('');
	const [isSearching, setIsSearching] = useState(false);
	const debounceDelay = 500;

	const handleInputChange = (event, newInputValue) => {
		if (newInputValue.length === 0) {
			if (isDropdownOpen) setIsDropdownOpen(false);
		} else {
			if (!isDropdownOpen) setIsDropdownOpen(true);
		}
		setInputValue(newInputValue);
		setOptions([]);
		setHelperText('');
		setSelectedFromOptions(false);
	};

	const handleSelectOption = (event, newValue) => {
		if (newValue && newValue.trim() !== '' && !options.some(option => option === newValue)) {
			setOptions([...options, newValue]);
		}
		setValue(newValue);
		setSelectedFromOptions(true);
	};

	useEffect(() => {
		if (inputValue.length && inputValue.length >= 1 && !selectedFromOptions) {
			const fetchData = async () => {
				setIsSearching(true);
				const encodedEmail = encodeURIComponent(inputValue);
				const userEmails = await searchUserByEmail(encodedEmail);
				setFetchedUsers(userEmails);
				const emailOptions = userEmails.map(user => user.user_email_id);
				setOptions(emailOptions);

				if (userEmails.length === 0) {
					setNoOptionsText('No match found');
				} else {
					setNoOptionsText('');
				}
				setIsSearching(false);
			};

			const debounceTimer = setTimeout(fetchData, debounceDelay);

			return () => clearTimeout(debounceTimer);
		}
	}, [inputValue, searchUserByEmail, debounceDelay, selectedFromOptions]);

	const handleAddButtonClick = () => {
		if (inputValue.trim() === '') {
			setHelperText('Please type an email address.');
			return;
		}

		const selectedUser = fetchedUsers.find(option => option.user_email_id === value);
		if (selectedUser && selectedUser.user_email_id) {
			setHelperText('');
			onOptionSelect(selectedUser);
		} else {
			setHelperText('Please add a valid email address.');
		}
	};

	const createHighlightParts = (option, inputValue) => {
		// Use memoization to avoid recomputing the same splits
		const memoKey = `${option}-${inputValue}`;
		const highlightMemo = {};
		if (highlightMemo[memoKey]) {
			return highlightMemo[memoKey];
		}

		const regex = new RegExp(`(${inputValue})`, 'i');
		const parts = option.split(regex);

		highlightMemo[memoKey] = parts;

		return parts;
	};

	const renderOption = (props, option) => {
		if (inputValue.length >= 1) {
			const parts = createHighlightParts(option, inputValue);

			return (
				<li {...props} key={option}>
					<Grid container alignItems="center">
						<Grid item sx={{ display: 'flex', width: 44 }}>
							<PersonAdd sx={{ color: 'text.secondary' }} />
						</Grid>
						<Grid item sx={styles.optionLabelText}>
							<Typography variant="body2" color="text.secondary">
								{parts.map((part, index) => {
									return part.toLowerCase() === inputValue.toLowerCase() ? (
										<span key={index} style={{ fontWeight: 'bold', color: colors.gray_700 }}>
											{part}
										</span>
									) : (
										part
									);
								})}
							</Typography>
						</Grid>
					</Grid>
				</li>
			);
		}

		return (
			<li {...props} key={option}>
				<Grid container alignItems="center">
					<Grid item sx={{ display: 'flex', width: 44 }}>
						<PersonAdd sx={{ color: 'text.secondary' }} />
					</Grid>
					<Grid item sx={styles.optionLabelText}>
						<Typography variant="body2" color="text.secondary">
							{option}
						</Typography>
					</Grid>
				</Grid>
			</li>
		);
	};

	return (
		<Box>
			<Box sx={styles.serachContainer}>
				<Autocomplete
					id="search-user-email"
					open={isDropdownOpen}
					onClose={() => setIsDropdownOpen(false)}
					sx={styles.autoCompleteInput}
					getOptionLabel={option => (typeof option === 'string' ? option : '')}
					filterOptions={x => x}
					options={options}
					autoComplete
					includeInputInList
					filterSelectedOptions
					value={value}
					noOptionsText={isSearching ? 'Searching...' : noOptionsText}
					onChange={handleSelectOption}
					onInputChange={handleInputChange}
					renderInput={params => (
						<TextField {...params} label="Email" fullWidth size="small" sx={styles.placholderText} />
					)}
					renderOption={renderOption}
				/>
				<Box sx={{ ml: 1 }}>
					<CustomButton title="Add" style={styles.addButton} onClick={handleAddButtonClick} />
				</Box>
			</Box>
			<Box sx={{ minHeight: 20 }}>
				{helperText && <Typography sx={{ fontSize: 12, color: 'red', ml: 1 }}>{helperText}</Typography>}
			</Box>
		</Box>
	);
};

const styles = {
	addButton: {
		height: '40px',
		fontSize: '16px',
		padding: '10px 30px'
	},
	autoCompleteInput: {
		width: '100%',
		margin: '0 auto',
		'.MuiAutocomplete-endAdornment': {
			display: 'none'
		}
	},
	optionLabelText: {
		width: 'calc(100% - 44px)',
		wordWrap: 'break-word'
	},
	placholderText: {
		'& .MuiFormLabel-root': {
			fontSize: '0.9rem'
		}
	},
	serachContainer: { display: 'flex', alignItems: 'center' }
};

export default SearchUser;
