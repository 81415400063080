export const getTruncatedText = (text, length = 20) => {
	if (text.length <= length) return text;
	return `${text.substring(0, length)}...`;
};

export const capitalizeAfterSpace = inputString => {
	if (inputString) {
		return inputString.replace(/(^|\s)(\w)/g, function (match) {
			return match.toUpperCase();
		});
	}
	return '';
};

export const isAdmin = (currentUser, selectedDB) => {
	const currentUserId = currentUser?.user_id;
	const DBUserId = selectedDB?.user_id;
	return DBUserId === currentUserId;
};

export const sortArrayByProperty = (arr, property) => {
	if (!Array.isArray(arr)) {
		// eslint-disable-next-line no-console
		console.log('Input is not an array');
		return [];
	}

	arr.sort((a, b) => {
		const valueA = a[property].toLowerCase();
		const valueB = b[property].toLowerCase();
		return valueA.localeCompare(valueB);
	});
};
