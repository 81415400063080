import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function ErrorFallback({ error }) {
	return (
		<Box style={styles.container}>
			<Typography>{error.message}</Typography>
		</Box>
	);
}

const styles = {
	container: {
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	}
};

ErrorFallback.propTypes = {
	error: PropTypes.object
};
