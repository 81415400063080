export const colors = {
	white: '#FFFFFF',
	black: '#000000',
	grayBgColor: '#DCDCDC',
	gray_200: '#EFEFEF',
	gray_300: '#D0D5DD',
	gray_500: '#667085',
	gray_700: '#344054',
	gray_900: '#101828',
	grayDark: '#949494',
	grayBorder: '#B5B5B5',
	grayLight: '#F2F2F2',
	chatText: '#222222'
};
