import { createContext, useCallback, useContext } from 'react';
import axios from 'axios';
import { UserSessionContext } from './UserSessionContext';
import { parseGetAllChats } from '../utils/dataparser';
import { sortArrayByProperty } from '../utils/utils';

const AxiosService = axios.create({
	baseURL: process.env.REACT_APP_API_URL
});

export const ApiContext = createContext();

const ApiContextProvider = ({ children }) => {
	const { setShowLoader, setCurrentUser, setAllDatabases, setDatabaseUsers } = useContext(UserSessionContext);

	const get = useCallback(
		async (endpoint, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.get(endpoint)
				.then(res => {
					if (process.env.REACT_APP_ENV === 'dev') {
						// eslint-disable-next-line
						console.log(`GET: ${endpoint} res`, res.data);
					}
					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					// eslint-disable-next-line
					console.log(`GET: ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	const post = useCallback(
		async (endpoint, data, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.post(endpoint, data)
				.then(res => {
					if (process.env.REACT_APP_ENV === 'dev') {
						// eslint-disable-next-line
						console.log(`POST: ${endpoint} res`, res.status);
					}
					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					// eslint-disable-next-line
					console.log(`POST - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	const deleteDB = useCallback(
		async (endpoint, showLoader = true) => {
			setShowLoader(showLoader);
			return AxiosService.delete(endpoint)
				.then(res => {
					if (process.env.REACT_APP_ENV === 'dev') {
						// eslint-disable-next-line no-console
						console.log(`DELETE: ${endpoint} res`, res.status);
					}

					return { success: true, errorMsg: '', response: res.data };
				})
				.catch(err => {
					// eslint-disable-next-line no-console
					console.log(`DELETE - ${endpoint} err`, err);
					return { success: false, errorMsg: err.message, response: {} };
				})
				.finally(() => setShowLoader(false));
		},
		[setShowLoader]
	);

	// below are the alpthabetical order of APIs

	const getDatabaseDetailsByID = useCallback(
		async databseId => {
			const response = await get(`/database/${databseId}/`);
			return response;
		},
		[get]
	);

	const getUserDetailsByID = useCallback(
		async userId => {
			const response = get(`/user/${userId}/`);
			return response;
		},
		[get]
	);

	const getAllDatabasesByUserId = useCallback(
		async userId => {
			const { success, response } = await get(`database_user_access/?user_id=${userId}`);

			if (success && response?.response_data.length) {
				const databasesByUserId = response.response_data;

				if (databasesByUserId.length > 0) {
					const detailsPromises = databasesByUserId.map(async databaseObject => {
						const { database_id } = databaseObject;
						return getDatabaseDetailsByID(database_id);
					});

					// Use Promise.all to await all details requests
					const details = await Promise.all(detailsPromises);

					const responseDatas = details
						.filter(detail => detail.success && detail.response.response_status === 200)
						.map(detail => detail.response.response_data);

					sortArrayByProperty(responseDatas, 'name');
					setAllDatabases(responseDatas);
				} else {
					setAllDatabases([]);
				}
			} else {
				setAllDatabases([]);
			}

			return {
				success,
				errorMsg: response.success ? response.description : ''
			};
		},
		[get, getDatabaseDetailsByID, setAllDatabases]
	);

	const getUsersByDatabaseId = useCallback(
		async selectedDBId => {
			const { success, response } = await get(`database_user_access/?database_id=${selectedDBId}`);

			if (success && response?.response_data.length) {
				const usersByDBIds = response.response_data;

				if (usersByDBIds.length > 0) {
					const userDetailsPromises = usersByDBIds.map(async userObject => {
						const { user_id } = userObject;
						return getUserDetailsByID(user_id);
					});

					// Use Promise.all to await all details requests
					const details = await Promise.all(userDetailsPromises);

					const allUsersByDBId = details
						.filter(detail => detail.success && detail.response.response_status === 200)
						.map(detail => detail.response.response_data);
					setDatabaseUsers(allUsersByDBId);

					return { success: true, errorMsg: '', usersData: allUsersByDBId };
				}
				setDatabaseUsers([]);
				return { success: true, errorMsg: '', usersData: [] };
			}
			return {
				success: false,
				errorMsg: response.description ? response.description : '',
				usersData: []
			};
		},
		[get, getUserDetailsByID, setDatabaseUsers]
	);

	const createDBUser = useCallback(
		async payload => {
			const { success, response, errorMsg } = await post(`/database_user_access/`, payload);
			if (success) {
				const { response_data } = response;
				const { user_id, database_id } = response_data;

				if (user_id) {
					await getUsersByDatabaseId(database_id);
					return { success: true, errorMsg: '', response_data };
				}
			}
			return { success: false, errorMsg: errorMsg ? errorMsg : response?.description || '', response_data: [] };
		},

		[getUsersByDatabaseId, post]
	);

	const createDatabase = useCallback(
		async payload => {
			const { success, response, errorMsg } = await post(`/database/`, payload);

			if (success) {
				const { response_data } = response;
				const { user_id } = response_data;

				if (user_id) {
					await getAllDatabasesByUserId(user_id);
					return { success: true, errorMsg: '', response_data };
				}
			}
			return { success: false, errorMsg: errorMsg ? errorMsg : response?.description || '', response_data: [] };
		},
		[getAllDatabasesByUserId, post]
	);

	const createUserWithCognitoId = useCallback(
		async cognitoUser => {
			const req = {
				cognito_id: cognitoUser.cogId,
				name: cognitoUser.name,
				user_email_id: cognitoUser.email
			};
			const { success, response } = await post(`/user/`, req);
			const { response_data } = response;
			return { success: success, errorMsg: response?.description ? response?.description : '', response_data };
		},
		[post]
	);

	const deleteDatabseById = useCallback(
		async (payload, currentUserId) => {
			const { success, response } = await deleteDB(`/database/${payload.database_id}/`);

			const { description, response_code } = response;
			if (success && response_code === 1) {
				if (!currentUserId) {
					return;
				}
				await getAllDatabasesByUserId(currentUserId);
				return { success: true, errorMsg: '' };
			}
			return { success: false, errorMsg: description ? description : '', response_data: [] };
		},
		[deleteDB, getAllDatabasesByUserId]
	);

	const deleteChatHistory = useCallback(
		async (userId, DBId) => {
			const res = await deleteDB(`/message/?database_id=${DBId}&user_id=${userId}`);

			return res;
		},
		[deleteDB]
	);

	const getAllDatabases = useCallback(async () => {
		const { success, response } = await get(`/database/`);
		if (success && response?.response_data.length) {
			setAllDatabases(response?.response_data);
		}

		return response;
	}, [get, setAllDatabases]);

	const getUserByCogId = useCallback(
		async cognitoId => {
			const { success, response, errorMsg } = await get(`/user/?cognito_id=${cognitoId}`);
			if (success && response?.response_data.length > 0) {
				const { user_id = null, name = '', user_email_id = '', cognito_id = null } = response?.response_data[0] || {};
				return setCurrentUser({
					user_id,
					name,
					user_email_id,
					cognito_id
				});
			}
			setCurrentUser({});
			return { success: success, errorMsg: errorMsg };
		},
		[get, setCurrentUser]
	);

	const getUserByEmail = useCallback(
		async email => {
			const { success, response, errorMsg } = await get(`/user/?email_id=${email}`);
			return { success, errorMsg: errorMsg || response?.description || '', response: response.response_data };
		},
		[get]
	);

	const getChatMessagesById = useCallback(
		async (userId, DBId) => {
			const res = await get(`/message/?user_id=${userId}&database_id=${DBId}`);
			return parseGetAllChats(res && res?.response && res?.response?.response_data);
		},
		[get]
	);

	const postQuery = useCallback(
		async query => {
			const { success, response } = await post(`/db_query_engine/`, query, false);
			const { response_data, response_code } = response;
			if (success) {
				if (response_code === 1 && response_data) {
					return { success: true, errorMsg: '', response: response_data };
				}
			}

			return { success: false, errorMsg: 'Something went wrong, please try again.', response: '' };
		},
		[post]
	);

	const searchUserByEmail = useCallback(
		async searchText => {
			const { success, response } = await get(`/user/?search_email=${searchText}`);
			if (success) {
				const { response_data, response_code } = response;
				if (response_code === 1 && response_data) {
					return response_data;
				}
			}
			return [];
		},
		[get]
	);

	return (
		<ApiContext.Provider
			value={{
				createDBUser,
				createDatabase,
				createUserWithCognitoId,
				deleteDatabseById,
				deleteChatHistory,
				getAllDatabases,
				getAllDatabasesByUserId,
				getUserByCogId,
				getUserByEmail,
				getUserDetailsByID,
				getUsersByDatabaseId,
				getChatMessagesById,
				postQuery,
				searchUserByEmail
			}}
		>
			{children}
		</ApiContext.Provider>
	);
};

export default ApiContextProvider;
