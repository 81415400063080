import { v4 as uuidv4 } from 'uuid';

export const parseGetAllChats = allMessages => {
	const chatArray = [];

	allMessages.forEach(obj => {
		const you_obj = { ...obj, id: uuidv4(), sender: 'You', message: obj.question };
		const gpt_obj = { ...obj, id: uuidv4(), sender: 'GPT', message: obj.answer };
		chatArray.push(you_obj, gpt_obj);
	});

	return { success: true, response: chatArray };
};
