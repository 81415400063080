import React from 'react';
import BaseModal from './BaseModal';

const DatabaseDiagramModal = ({ open, onClose, selectedListitem }) => {
	return (
		<BaseModal
			title="Database ER Diagram"
			fontWeight="700"
			open={open}
			onClose={onClose}
			maxWidth="xl"
			isPositionTop={true}
			closeOnBackgroundClick={false}
		>
			<>
				<img
					src={`data:image/jpeg;base64,${selectedListitem?.base64_er_diagram_data}`}
					style={{ width: '100%' }}
					alt="Modal Image"
				/>
			</>
		</BaseModal>
	);
};

export default DatabaseDiagramModal;
