import React, { useRef, useState } from 'react';
import styles from './CustomInput.module.scss';
import { TextField } from '@mui/material';
import { Box, IconButton, InputAdornment } from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

function CustomInput({
	containerStyle,
	type,
	name,
	onChange,
	placeholder,
	value,
	inputStyle,
	disabled,
	className,
	inputTitle,
	variant = 'outlined',
	isSearchIconVisible = false,
	showVisibilityIcon = false,
	required = false,
	isError = false,
	autoComplete = 'off',
	errorMsg = ''
}) {
	const inputRef = useRef(null);
	const [showPassword, setShowPassword] = useState(showVisibilityIcon);

	const handleClickShowPassword = () => setShowPassword(show => !show);

	const handleMouseDownPassword = event => {
		event.preventDefault();
	};

	const inputType = type === 'password' && !showPassword ? 'text' : type;

	return (
		<Box style={containerStyle} className={className}>
			<TextField
				ref={inputRef}
				variant={variant}
				required={required}
				type={inputType}
				name={name}
				onChange={onChange}
				placeholder={placeholder}
				label={inputTitle}
				error={isError}
				style={{ ...inputStyle, width: '100%' }}
				disabled={disabled}
				value={value}
				autoComplete={autoComplete}
				helperText={errorMsg}
				InputProps={{
					style: { fontSize: '16px', height: isSearchIconVisible ? '40px' : 'auto' },
					startAdornment: isSearchIconVisible && (
						<InputAdornment position="start">
							<SearchIcon className={styles.inputIcon} />
						</InputAdornment>
					),
					endAdornment: showVisibilityIcon && (
						<InputAdornment position="start">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? (
									<VisibilityOff className={styles.inputIcon} />
								) : (
									<VisibilityIcon className={styles.inputIcon} />
								)}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
		</Box>
	);
}

export default CustomInput;
