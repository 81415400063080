import { Box, DialogActions } from '@mui/material';
import React, { useState } from 'react';
import BaseModal from './BaseModal';
import CustomButton from './CustomButton/Custombutton';
import CustomInput from './CustomInput/CustomInput';

const AddDatabaseModal = ({ open, onClose, onSubmit }) => {
	const initialState = {
		databaseName: '',
		username: '',
		password: '',
		host: '',
		port: 3306
	};
	const [formData, setFormData] = useState(initialState);
	const [formErrors, setFormErrors] = useState({});

	const handleChange = e => {
		const { name, value } = e.target;
		const updatedFormData = { ...formData, [name]: value };

		const updatedFormErrors = { ...formErrors };
		updatedFormErrors[name] = !value;

		setFormData(updatedFormData);
		setFormErrors(updatedFormErrors);
	};

	const validateForm = () => {
		const ipv4Regex = /^(\d{1,3}\.){3}\d{1,3}$/;
		const errors = {
			databaseName: !formData.databaseName || formData.databaseName.length <= 2,
			username: !formData.username || formData.username.length <= 2,
			password: !formData.password || formData.password.length <= 3,
			host: !formData.host || !ipv4Regex.test(formData.host) // Validate against the regex
			// port: !formData.port
		};

		setFormErrors(errors);
		return Object.values(errors).every(error => !error);
	};

	const handleSubmit = e => {
		e.preventDefault();
		const isFormValid = validateForm();

		if (isFormValid) {
			onSubmit(formData);
			setFormData(initialState);
			onClose();
		}
	};
	const inputStyle = { flex: 1, margin: '0 10px' };

	return (
		<BaseModal title="Add DataBase" fontWeight="700" open={open} onClose={onClose}>
			<form onSubmit={handleSubmit}>
				<Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
					<CustomInput
						name="databaseName"
						onChange={handleChange}
						value={formData.databaseName}
						inputTitle="Database name"
						containerStyle={inputStyle}
						autoComplete="off"
						placeholder="Enter Database Name"
						isError={formErrors.databaseName}
						errorMsg={formErrors.databaseName ? 'Required minimum 3 characters' : ''}
					/>
					<CustomInput
						name="username"
						inputTitle="Username"
						containerStyle={inputStyle}
						autoComplete="off"
						placeholder="Enter Username"
						onChange={handleChange}
						value={formData.username}
						isError={formErrors.username}
						errorMsg={formErrors.username ? 'Required minimum 3 characters' : ''}
					/>
				</Box>

				<Box sx={{ display: 'flex', justifyContent: 'space-between', my: 2 }}>
					<CustomInput
						name="password"
						inputTitle="Password"
						containerStyle={inputStyle}
						autoComplete="off"
						placeholder="Enter Password"
						type={'password'}
						showVisibilityIcon={false}
						onChange={handleChange}
						value={formData.password}
						isError={formErrors.password}
						errorMsg={formErrors.password ? 'Required minimum 4 characters' : ''}
					/>
					<CustomInput
						name="host"
						inputTitle="Host"
						containerStyle={inputStyle}
						autoComplete="off"
						placeholder="Enter Host"
						onChange={handleChange}
						value={formData.host}
						isError={formErrors.host}
						errorMsg={formErrors.host ? 'Required host format. Ex: 192.168.1.1' : ''}
					/>
					<CustomInput
						name="port"
						inputTitle="Port: 3306"
						type="Number"
						containerStyle={inputStyle}
						placeholder="Enter Port"
						onChange={handleChange}
						// value={formData.port}
						isError={formErrors.port}
						disabled={true}
					/>
				</Box>
				<DialogActions sx={{ justifyContent: 'center' }}>
					<CustomButton type={'submit'} title="Add" />
				</DialogActions>
			</form>
		</BaseModal>
	);
};

export default AddDatabaseModal;
