import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import styles from './SignUp.module.css';
import ErrorAlert from '../../components/ErrorAlert';
import { RingLoader } from 'react-spinners';
import SignupScreenWrapper from '../../components/SignupScreenWrapper/SignupScreenWrapper';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomButton from '../../components/CustomButton/Custombutton';

function SignUp() {
	const { signUpUser } = useContext(AmplifyAuthContext);
	const [loading, setLoading] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const navigate = useNavigate();

	const handleShowError = message => {
		setErrorMessage(message);
		setErrorOpen(true);
	};

	const handleCloseError = () => {
		setErrorOpen(false);
	};

	const handleSignUp = async event => {
		event.preventDefault();

		const { email, password, userName } = event.target.elements;

		try {
			setLoading(true);
			const { success, errorMsg, response } = await signUpUser(email.value, password.value, userName.value);

			if (!success) {
				if (errorMsg === 'Username should be an email.') {
					handleShowError('Email is not valid. Please enter a valid email address.');
				} else {
					handleShowError(errorMsg);
				}
			} else {
				const otpDeliveryDestination = response.codeDeliveryDetails.Destination;

				navigate('/otp', {
					state: {
						email: email.value,
						otpDeliveryDestination
					}
				});
			}
		} catch (error) {
			handleShowError('An error occurred');
		} finally {
			setLoading(false);
		}
	};

	return (
		<SignupScreenWrapper>
			{loading && (
				<div className={styles.loaderOverlay}>
					<RingLoader color="#ffffff" size={60} />
				</div>
			)}
			<form onSubmit={handleSignUp}>
				<h2>Sign Up</h2>
				<CustomInput
					placeholder="John Doe"
					inputTitle="Name"
					name="userName"
					type={'text'}
					className={styles.formItems}
					required
				/>

				<CustomInput
					placeholder="john@gmail.com"
					inputTitle="Email"
					name="email"
					type={'text'}
					className={styles.formItems}
					required
				/>

				<CustomInput
					placeholder="********"
					inputTitle="Password"
					name="password"
					type={'password'}
					showVisibilityIcon={true}
					className={styles.formItems}
					autoComplete="new-password"
					required
				/>

				<div className={styles.loginLink}>
					<Link to={'/login'}>Already Registered? Log In.</Link>
				</div>

				<br />

				<CustomButton title="Submit" type={'submit'} style={{ width: '100%' }} />
			</form>
			<ErrorAlert open={errorOpen} message={errorMessage} onClose={handleCloseError} />
		</SignupScreenWrapper>
	);
}

export default SignUp;
