import { Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from '../context/ApiContext';
import { UserSessionContext } from '../context/UserSessionContext';
import BaseModal from './BaseModal';

const DatabaseDetailsModal = ({ open, onClose, onSubmit, selectedListitem }) => {
	const { showLoader } = useContext(UserSessionContext);
	const { getUserDetailsByID } = useContext(ApiContext);
	const [databaseAdminDetails, setDatabaseAdminDetails] = useState({});

	useEffect(() => {
		setDatabaseAdminDetails({});
		const fetchData = async () => {
			const userId = selectedListitem?.user_id;
			if (open && userId) {
				const { success, response } = await getUserDetailsByID(userId);
				if (success) {
					const { response_code, response_data } = response;
					if (response_code === 1 && response_data) {
						setDatabaseAdminDetails(response_data);
					}
				}
			}
		};

		fetchData();
	}, [getUserDetailsByID, open, selectedListitem?.user_id, setDatabaseAdminDetails]);

	return (
		<BaseModal
			title="Database Detials"
			fontWeight="700"
			open={open}
			onClose={onClose}
			maxWidth="sm"
			isPositionTop={true}
			closeOnBackgroundClick={false}
		>
			<>
				{showLoader ? (
					<Typography colSpan={3}>Loading...</Typography>
				) : (
					<>
						<Typography>
							Database name: <b>{selectedListitem?.name}</b>
						</Typography>
						<Typography>
							User name: <b>{selectedListitem?.user}</b>
						</Typography>
						<Typography>
							Password: {selectedListitem?.password ? '*'.repeat(selectedListitem?.password.length) : ''}
						</Typography>
						<Typography>
							Host: <b>{selectedListitem?.host}</b>
						</Typography>
						<Typography>
							Port: <b>{selectedListitem?.host}</b>
						</Typography>
						{databaseAdminDetails?.user_email_id && (
							<Typography>
								Created by: <b>{databaseAdminDetails.user_email_id}</b>
							</Typography>
						)}
					</>
				)}
			</>
		</BaseModal>
	);
};

export default DatabaseDetailsModal;
