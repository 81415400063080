import React, { useContext } from 'react';
import { CardMedia, Typography, Box, Fade } from '@mui/material';
import chatPlaceholder from '../asset/images/chatPlaceholder.png';
import createDB from '../asset/images/createDB.svg';
import { UserSessionContext } from '../context/UserSessionContext';

const ChatPlaceholder = () => {
	const { allDatabases, initialLoading } = useContext(UserSessionContext);

	return (
		<Box style={styles.centeredContent}>
			{initialLoading ? null : (
				<Box style={styles.imageContainer}>
					<Fade in={!initialLoading} mountOnEnter unmountOnExit>
						<CardMedia
							component="img"
							src={allDatabases.length > 0 ? chatPlaceholder : createDB}
							alt="chat placeholder"
							style={{
								cursor: 'pointer',
								width: '360px',
								height: '360px'
							}}
						/>
					</Fade>

					<Fade in={!initialLoading} mountOnEnter unmountOnExit>
						<Box style={styles.textContainer}>
							{allDatabases.length > 0 ? (
								<Typography>Please select a database from the sidebar</Typography>
							) : (
								<Typography>Please create a database</Typography>
							)}
						</Box>
					</Fade>
				</Box>
			)}
		</Box>
	);
};

const styles = {
	centeredContent: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh'
	},
	imageContainer: {
		textAlign: 'center'
	},
	image: {
		maxWidth: '100%',
		maxHeight: '100%'
	},
	textContainer: {
		textAlign: 'center',
		marginTop: '10px'
	}
};

export default ChatPlaceholder;
