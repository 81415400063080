import React, { useContext, useState } from 'react';
import styles from './Home.module.scss';
import { Box, Grid } from '@mui/material';
import ConfirmationAlert from '../../components/ConfirmationAlert';
import { useNavigate } from 'react-router-dom';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import SideNav from '../../components/SideNav/SideNav';
import { drawerWidth } from '../../constant/Constant';
import ChatSection from '../../components/ChatSection';
import { UserSessionContext } from '../../context/UserSessionContext';
import Loader from '../../components/Loader';
import ChatPlaceholder from '../../components/ChatPlaceholder';
import CustomSnackbar from '../../components/CustomSnackbar';

function Home() {
	const navigate = useNavigate();
	const { signOutUser } = useContext(AmplifyAuthContext);
	const { allDatabases, showLoader, selectedDB } = useContext(UserSessionContext);
	const [showConfirmLogout, setShowConfirmLogout] = useState(false);
	const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

	const [snackbarDetails, setSnackbarDetails] = useState({
		variant: 'default',
		title: ''
	});

	const handleLogout = async () => {
		await signOutUser();
		navigate('/login');
	};

	return (
		<Box>
			{showLoader && <Loader />}
			<Grid>
				<Grid item xs={2}>
					<SideNav />
				</Grid>
				<Grid
					item
					style={{ marginLeft: drawerWidth }}
					xs={10}
					sx={{
						pt: { sm: 0, md: 0 }
					}}
					className={styles.chatContainer}
				>
					{selectedDB && allDatabases.length > 0 ? (
						<ChatSection setIsSnackbarVisible={setIsSnackbarVisible} setSnackbarDetails={setSnackbarDetails} />
					) : (
						<ChatPlaceholder />
					)}
				</Grid>
			</Grid>
			<ConfirmationAlert
				open={showConfirmLogout}
				label={'Log Out'}
				message="Are you sure you want to log out?"
				onConfirm={() => handleLogout()}
				onCancel={() => setShowConfirmLogout(false)}
			/>
			<CustomSnackbar variant={snackbarDetails.variant} title={snackbarDetails.title} isVisible={isSnackbarVisible} />
		</Box>
	);
}

export default Home;
