import React, { useContext, useEffect, useState } from 'react';
import styles from './NavBarListItems.module.scss';
import {
	Box,
	Fade,
	IconButton,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Menu,
	MenuItem,
	Tooltip,
	Typography
} from '@mui/material';
import { colors } from '../../../constant/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { UserSessionContext } from '../../../context/UserSessionContext';
import { ApiContext } from '../../../context/ApiContext';
import { navListConstants, navListoptions } from '../../../constant/Constant';
import { isAdmin } from '../../../utils/utils';

export default function NavBarListItems({
	onListItemClick,
	onAddUser,
	onViewDatabase,
	onDeleteDatabase,
	containerStyle
}) {
	const { getAllDatabasesByUserId, getChatMessagesById } = useContext(ApiContext);
	const {
		allDatabases,
		authenticatedUser,
		currentUser,
		selectedDB,
		setSelectedDB,
		setAllDatabases,
		setInitialLoading,
		initialLoading
	} = useContext(UserSessionContext);

	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedListItem, setSelectedListItem] = useState(null);

	const handleOpenMenu = event => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleOptionClick = (option, db) => {
		handleCloseMenu();

		switch (option) {
			case navListConstants.addUser:
				onAddUser(db);
				break;
			case navListConstants.viewDatabase:
				onViewDatabase(db);
				break;
			case navListConstants.deleteDatabase:
				onDeleteDatabase(db);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const fetchData = async () => {
			setInitialLoading(true);
			setAllDatabases([]);

			if (authenticatedUser && currentUser?.user_id) {
				await getAllDatabasesByUserId(currentUser.user_id);
			}
			setInitialLoading(false);
		};

		fetchData();
	}, [authenticatedUser, currentUser, getAllDatabasesByUserId, setAllDatabases, setInitialLoading]);

	const handleListItemClicked = async item => {
		await setSelectedDB(item);
		onListItemClick(item);
		if (selectedDB?.database_id === item?.database_id) return;
		await getChatMessagesById(currentUser?.user_id, item.database_id);
	};

	return (
		<Box>
			{initialLoading && allDatabases.length === 0 ? (
				<Typography textAlign="center" fontSize={14} color={colors.black} mt={5}>
					Loading...
				</Typography>
			) : allDatabases.length === 0 ? (
				<Typography textAlign="center" fontSize={14} color={colors.black} mt={5}>
					No Database Added
				</Typography>
			) : (
				<>
					<List
						sx={{
							mt: 2,
							width: '100%',
							position: 'relative',
							overflow: 'auto',
							maxHeight: '70vh',
							pt: 0,
							flex: 1,
							'& ul': { padding: 0 }
						}}
					>
						{allDatabases.map((item, i) => (
							<Fade
								key={item.database_id}
								in={!initialLoading}
								style={{ transitionDelay: !initialLoading ? `${i * 130}ms` : '0ms' }}
							>
								<ListItem
									key={item.database_id}
									sx={{
										bgcolor: selectedDB?.database_id === item?.database_id ? colors.grayBorder : colors.white
									}}
									className={styles.listItem}
									onClick={() => handleListItemClicked(item)}
								>
									<ListItemButton>
										<ListItemText
											primaryTypographyProps={{
												style: {
													fontSize: '14px',
													whiteSpace: 'nowrap',
													overflow: 'hidden',
													textOverflow: 'ellipsis'
												}
											}}
											primary={item.name}
										/>
										<IconButton
											sx={{ p: 0 }}
											aria-label="more"
											id={`more-button-${i}`}
											onClick={e => {
												setSelectedListItem(item);
												handleOpenMenu(e);
											}}
										>
											<Tooltip title="more" variant="soft">
												<MoreVertIcon />
											</Tooltip>
										</IconButton>
									</ListItemButton>
								</ListItem>
							</Fade>
						))}
					</List>
					<Menu
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={handleCloseMenu}
						PaperProps={{
							style: {
								maxHeight: 48 * 4.5,
								width: '20ch'
							},
							sx: {
								boxShadow: 1
							}
						}}
					>
						{navListoptions.map((item, index) => (
							<MenuItem
								key={index}
								onClick={() => handleOptionClick(item, selectedListItem)}
								disabled={item === navListConstants.deleteDatabase && !isAdmin(currentUser, selectedListItem)}
							>
								{item}
							</MenuItem>
						))}
					</Menu>
				</>
			)}
		</Box>
	);
}
