import React from 'react';
import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const CloseIcon = ({ onClick, sx }) => {
	return (
		<IconButton onClick={onClick} sx={sx}>
			<Close />
		</IconButton>
	);
};

export default CloseIcon;
