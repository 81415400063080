import React, { useContext, useState } from 'react';
import styles from './SideNav.module.scss';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';

import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PowerSettingsNew from '@mui/icons-material/PowerSettingsNew';

import { UserSessionContext } from '../../context/UserSessionContext';
import ConfirmationAlert from '../ConfirmationAlert';
import { AmplifyAuthContext } from '../../context/AmplifyAuthContext';
import { drawerWidth, notificationTypes } from '../../constant/Constant';
import NavHeader from './NavHeader/NavHeader';
import NavBarListItems from './NavBarListItems/NavBarListItems';
import { getTruncatedText } from '../../utils/utils';
import CustomButton from '../CustomButton/Custombutton';
import AddDatabaseModal from '../AddDatabaseModal';
import AddDBUserModal from '../AddDBUserModal';
import { ApiContext } from '../../context/ApiContext';
import ErrorAlert from '../ErrorAlert';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { colors } from '../../constant/colors';
import CustomSnackbar from '../CustomSnackbar';
import DatabaseDetailsModal from '../DatabaseDetailsModal';

export default function SideNav({ window }) {
	const navigate = useNavigate();
	const { createDatabase, createDBUser, deleteDatabseById, getUserByEmail } = useContext(ApiContext);
	const {
		authenticatedUser,
		selectedDB,
		setSelectedDB,
		currentUser,
		setAllDatabases,
		setAuthenticatedUser,
		setCognitoUser,
		setSelectedChat,
		setCurrentUser,
		setDatabaseUsers
	} = useContext(UserSessionContext);
	const { signOutUser } = useContext(AmplifyAuthContext);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [isDBModelOpen, setIsDBModalOpen] = useState(false);
	const [isUserModelOpen, setIsUserModalOpen] = useState(false);
	const [isDetailsModelOpen, setIsDetailsModalOpen] = useState(false);
	const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [selectedOptionDB, setSelectedOptionDB] = useState(null);
	const [modalDetails, setModalDetails] = useState({
		type: 'error',
		message: '',
		title: 'Error'
	});
	const [confirmationProps, setConfirmationProps] = useState({
		label: '',
		message: '',
		onConfirm: () => {},
		onCancel: () => {}
	});

	const [snackbarDetails, setSnackbarDetails] = useState({
		variant: 'default',
		title: ''
	});

	const handleOpenConfirmation = (label, message, onConfirm, onCancel) => {
		setConfirmationProps({ label, message, onConfirm, onCancel });
		setShowConfirmation(true);
	};

	const setModal = (type, title, message) => {
		setModalDetails({
			type,
			title,
			message
		});
		setErrorOpen(true);
	};

	const setSnackbar = (variant, title) => {
		setSnackbarDetails({
			variant,
			title
		});
		setIsSnackbarVisible(true);
	};

	const handleCloseDBModal = () => {
		setIsDBModalOpen(false);
	};

	const handleCloseUserModal = () => {
		setIsUserModalOpen(false);
	};

	const handleLogout = async () => {
		await signOutUser();
		setSelectedDB(null);
		setAllDatabases([]);
		setAuthenticatedUser(null);
		setCognitoUser(null);
		setSelectedChat(null);
		setCurrentUser(null);
		setDatabaseUsers(null);
		navigate('/login');
	};

	const handleAddDatabase = async data => {
		const payload = {
			name: data.databaseName,
			user: data.username,
			host: data.host,
			password: data.password,
			user_id: currentUser.user_id,
			...(data.port && { port: data.port })
		};

		const { success, errorMsg } = await createDatabase(payload);
		if (success) {
			return enqueueSnackbar(`Database created successfully`, {
				variant: 'success'
			});
		}
		setModal('error', 'Error', errorMsg);
	};

	const handlAddDataBaseUser = async data => {
		const { response } = await getUserByEmail(data.userEmail);

		if (response?.length > 0) {
			const { user_id } = response[0];
			const { success, errorMsg } = await createDBUser({
				user_id,
				database_id: selectedOptionDB.database_id
			});

			if (success) {
				return enqueueSnackbar(`User added successfully`, {
					variant: 'success'
				});
			}
			return enqueueSnackbar(`${errorMsg}`, {
				variant: 'warning',
				autoHideDuration: 6000,
				onClose: closeSnackbar,
				action: key => (
					<>
						<Button size="small" onClick={() => closeSnackbar(key)}>
							<Typography sx={{ fontSize: 12 }} color={colors.white}>
								Dismiss
							</Typography>
						</Button>
					</>
				)
			});
		}
		setModal('info', 'User Not Found', 'Please sign up to add this user');

		setErrorOpen(true);
	};
	const handleDeleteDB = async DB => {
		setShowConfirmation(false);
		const { success, errorMsg } = await deleteDatabseById(DB, currentUser.user_id);
		if (success) {
			if (selectedDB?.database_id === DB.database_id) setSelectedDB(null);
			return setSnackbar(notificationTypes.success, 'Database deleted successfully');
		}
		return setModal(notificationTypes.error, 'Error', errorMsg);
	};

	const onDeleteDatabase = db => {
		const message = (
			<span>
				Are you sure you want to delete the database <b>{db?.name}</b>?
			</span>
		);
		handleOpenConfirmation(
			'Delete Database',
			// `Are you sure you want to delete the database ${<strong>{db?.name}</strong>}?`,
			message,
			() => {
				handleDeleteDB(db);
			},
			() => setShowConfirmation(false)
		);
	};

	return (
		<>
			<Drawer
				variant="persistent"
				open={true}
				ModalProps={{
					keepMounted: true // Better open performance on mobile.
				}}
				sx={{
					display: { md: 'block', lg: 'block' },
					overflow: 'hidden',
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth }
				}}
				PaperProps={{ elevation: 2 }}
				className={styles.drawerContainer}
			>
				<Box className={styles.navContainer}>
					<NavHeader />
					<CustomButton
						title="Add Database"
						style={{ fontSize: '16px' }}
						onClick={() => setIsDBModalOpen(!isDBModelOpen)}
					/>
					<NavBarListItems
						onListItemClick={item => {
							setSelectedDB(item);
						}}
						onAddUser={db => {
							setSelectedOptionDB(db);
							setIsUserModalOpen(!isUserModelOpen);
						}}
						onViewDatabase={db => {
							setSelectedOptionDB(db);
							setIsDetailsModalOpen(true);
						}}
						onDeleteDatabase={db => onDeleteDatabase(db)}
					/>

					{/* Sign Out */}
					<Box className={styles.logoutContainer}>
						{authenticatedUser && currentUser?.name && (
							<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<AccountCircleRoundedIcon sx={{ fontSize: '35px', marginRight: '5px', marginLeft: '-3px' }} />
								<Box>
									<Typography className={styles.userName} sx={{ fontWeight: 'bold' }}>
										{getTruncatedText(currentUser?.name, 30) || ''}
									</Typography>
									<Typography className={styles.userName} title={authenticatedUser?.attributes?.email}>
										{getTruncatedText(authenticatedUser?.attributes?.email, 28)}
									</Typography>
								</Box>
							</Box>
						)}
						<IconButton
							sx={{ boxShadow: 3, marginLeft: 'auto' }}
							onClick={() => {
								handleOpenConfirmation(
									'Log Out',
									'Are you sure you want to log out?',
									() => {
										handleLogout();
										setShowConfirmation(false);
									},
									() => setShowConfirmation(false)
								);
							}}
						>
							<PowerSettingsNew className={styles.logoutButton} />
						</IconButton>
					</Box>
					<ConfirmationAlert
						open={showConfirmation}
						label={confirmationProps.label}
						message={confirmationProps.message}
						onConfirm={() => confirmationProps.onConfirm()}
						onCancel={() => confirmationProps.onCancel()}
					/>
					<AddDatabaseModal open={isDBModelOpen} onClose={handleCloseDBModal} onSubmit={handleAddDatabase} />
					<AddDBUserModal
						open={isUserModelOpen}
						onClose={handleCloseUserModal}
						selectedListitem={selectedOptionDB}
						onSubmit={handlAddDataBaseUser}
					/>
					<DatabaseDetailsModal
						open={isDetailsModelOpen}
						onClose={() => setIsDetailsModalOpen(false)}
						selectedListitem={selectedOptionDB}
					/>
					<ErrorAlert
						title={modalDetails.title}
						severity={modalDetails.type}
						open={errorOpen}
						message={modalDetails.message}
						onClose={() => setErrorOpen(false)}
					/>
					<CustomSnackbar
						variant={snackbarDetails.variant}
						title={snackbarDetails.title}
						isVisible={isSnackbarVisible}
					/>
				</Box>
			</Drawer>
		</>
	);
}
