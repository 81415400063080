import React from 'react';
import { RingLoader } from 'react-spinners';
import { colors } from '../constant/colors';

const Loader = () => {
	return (
		<div style={styles.loaderOverlay}>
			<RingLoader color={colors.white} size={60} />
		</div>
	);
};

const styles = {
	loaderOverlay: {
		position: 'fixed',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 9999
	}
};

export default Loader;
